import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container"> 
        <div className="row">             

          <div className="col-xs-12 col-md-6">
            {" "}
            <img style={{borderRadius: "4px", marginBottom:'24px'}} src="img/team.jpeg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>Founded and operated by two lifelong friends with a shared passion for pristine pools, we bring over a decade of combined experience in the pool service industry to every splash we make.</p>
              <p>What sets us apart is our personalized approach. At Castillo Pool Service, we treat every pool as if it were our own. We know that every pool is unique, just like every customer, and we tailor our services to meet your specific requirements. From meticulous cleaning to efficient repairs, we handle it all with a smile, ensuring your pool remains a source of joy and relaxation for years to come.</p>
              {/* <p>Why choose us? Because we understand that your pool is more than just water; it's a lifestyle. As a locally-owned and operated business, we pride ourselves on personalized service tailored to your unique needs. Your satisfaction is our priority, and we treat every pool as if it were our own.</p> */}
              <p>As a locally-owned and operated business, we are deeply invested in our community. We believe in fostering long-lasting relationships, not just with our customers but with our neighbors. With us, you're not just getting a pool service – you're gaining a trusted partner dedicated to the well-being of your oasis.</p>
              {/* <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
