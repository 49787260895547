import React from "react";
import { HiExternalLink } from "react-icons/hi";


export const Testimonials = (props) => {

  const yelpReviewLink = "https://www.yelp.com/biz/castillo-pool-service-pasadena-3?utm_campaign=www_business_share_popup&utm_medium=copy_link&utm_source=(direct)"
  
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-sm-6">
                  <div className="testimonial">
                    <div className="testimonial-content">
                      <p>{d.text}</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <div style={{textAlign:'center',padding:'24px 0'}}>

        <a href={yelpReviewLink}style={{fontWeight:700,fontSize:'16px'}} alt="Yelp reviews link" target="_blank">
          Visit us on Yelp for more reviews
          <HiExternalLink style={{marginLeft:'4px'}}/>
                    </a>
        </div>
      </div>
    </div>
  );
};
