import React from "react";
import { TfiBrushAlt } from "react-icons/tfi";
import { GiChemicalDrop } from "react-icons/gi";
import { GiAutoRepair } from "react-icons/gi";
import { GiAlgae } from "react-icons/gi";
import { GiSoap } from "react-icons/gi";
import { HiOutlineSparkles } from "react-icons/hi2";

  const service1 = {
    "name": "Pool Cleaning & Servicing",
    "text": "Routine cleaning prevents the buildup of debris, algae, and bacteria, maintaining water clarity and hygiene. It also extends the lifespan of pool equipment."
  }
  const service2 = {
    "name": "Pool & Spa Equipment Repairs",
    "text": "Regular checks on pumps, filters, and heaters enhance efficiency and prevent costly breakdowns. Well-maintained equipment also contributes to energy savings."
  }
  const service3 = {
    "name": "Algae Treatment & Filter Cleaning",
    "text": "Our technicians are experienced in the identification and treatment of all types of pool algae. We also carry out mitigation and prevention to keep your pool algae free."
  }
  const service4 = {
    "name": "Green to Clean",
    "text": "If your pool water has been sitting still in your backyard, don’t worry about it. We can take your water from green to clean without draining or replacing your pool water."
  }
  const service5 = {
    "name": "Chemical Balancing",
    "text": "Proper chemical balance ensures a safe and comfortable swimming environment. It prevents skin and eye irritation and protects the pool structure from corrosion."
  }
  const service6 = {
    "name": "Tile and Grout Cleaning",
    "text": "Aesthetic upkeep prevents the growth of mold and algae, preserving the visual appeal of your pool. It also helps in maintaining a smooth surface.",
  }

  const customIconStyles= {fontSize: '42px',width: '120px',height: '120px',padding: '40px 0',background: 'linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)',borderRadius: '50%',color: '#fff',boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.05)'}

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>We know that every pool is unique, just like every customer, and we tailor our services to meet your specific requirements.</p>
        </div>
        <div className="row">
                <div key={`${service1.name}`} className="col-md-4">
                  {" "}
                  <GiSoap style={customIconStyles} />
                  <div className="service-desc">
                    <h3>{service1.name}</h3>
                    <p>{service1.text}</p>
                  </div>
                </div>

                <div key={`${service2.name}`} className="col-md-4">
                  {" "}
                  <GiAutoRepair style={customIconStyles} />
                  <div className="service-desc">
                    <h3>{service2.name}</h3>
                    <p>{service2.text}</p>
                  </div>
                </div>
                
                <div key={`${service3.name}`} className="col-md-4">
                  {" "}
                  <GiAlgae style={customIconStyles} />
                  <div className="service-desc">
                    <h3>{service3.name}</h3>
                    <p>{service3.text}</p>
                  </div>
                </div>
                
                <div key={`${service4.name}`} className="col-md-4">
                  {" "}
                  <HiOutlineSparkles style={customIconStyles} />
                  <div className="service-desc">
                    <h3>{service4.name}</h3>
                    <p>{service4.text}</p>
                  </div>
                </div>
                
                <div key={`${service5.name}`} className="col-md-4">
                  {" "}
                  <GiChemicalDrop style={customIconStyles} />
                  <div className="service-desc">
                    <h3>{service5.name}</h3>
                    <p>{service5.text}</p>
                  </div>
                </div>
                
                <div key={`${service6.name}`} className="col-md-4">
                  {" "}
                  <TfiBrushAlt style={customIconStyles}/>
                  <div className="service-desc">
                    <h3>{service6.name}</h3>
                    <p>{service6.text}</p>
                  </div>
                </div>

        </div>
      </div>
    </div>
  );
};
